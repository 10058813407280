import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { cloneFnJSON } from '@vueuse/core'
import type { IManagerFormValue } from '@manager/types'
import { mergeForm } from '@manager/utils'

export type ILeaseCardStorageParams = {
  divisionId: string
  leaseId: string
  fieldGroupId: number
}

const createLeaseCardStorageQueryKey = ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) => [
  'companies',
  divisionId,
  'leases',
  leaseId,
  'storage',
  'node',
  fieldGroupId,
]

const _fetchLeaseCardStorage = ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) => {
  return fetchAPI<IManagerFormValue>(
    `/companies/${divisionId}/leases/${leaseId}/storage/node/${fieldGroupId}`,
    {
      query: {
        includeEmptyValues: false,
      },
    },
  )
}

export const useApiLeaseCardStorage = ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) => {
  return useQuery<IManagerFormValue>({
    queryKey: createLeaseCardStorageQueryKey({
      divisionId,
      leaseId,
      fieldGroupId,
    }),
    queryFn: () =>
      _fetchLeaseCardStorage({ divisionId, leaseId, fieldGroupId }),
  })
}

export const fetchLeaseCardStorage = async ({
  divisionId,
  leaseId,
  fieldGroupId,
}: ILeaseCardStorageParams) => {
  const queryClient = useQueryClient()
  const store = useManagerStore()

  try {
    const data = await queryClient.fetchQuery<IManagerFormValue>({
      queryKey: createLeaseCardStorageQueryKey({
        divisionId,
        leaseId,
        fieldGroupId,
      }),
      queryFn: () =>
        _fetchLeaseCardStorage({ divisionId, leaseId, fieldGroupId }),
    })

    if (store.formValueMap[fieldGroupId]) {
      mergeForm({
        target: store.formValueMap[fieldGroupId],
        source: cloneFnJSON(data ?? {}),
      })
    } else {
      store.formValueMap[fieldGroupId] = cloneFnJSON(data ?? {})
    }

    return data
  } catch (e) {
    // If failed to load the form value, reset it to an empty object
    if (!store.formValueMap[fieldGroupId]) {
      store.formValueMap[fieldGroupId] = {}
    }
  }
}
