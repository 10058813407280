import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { IManagerFormValue } from '@manager'

export const useApiLeaseCardStorageMutation = (
  divisionId: string,
  leaseId: string,
  fieldGroupId: number,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: IManagerFormValue) =>
      fetchAPI(
        `/companies/${divisionId}/leases/${leaseId}/storage/node/${fieldGroupId}`,
        {
          method: 'POST',
          body,
        },
      ),
    async onSuccess() {
      await queryClient.invalidateQueries({
        queryKey: [
          'companies',
          divisionId,
          'leases',
          leaseId,
          'storage',
          'node',
        ],
      })
    },
  })
}
