import {
  getCurrentFieldGroupId,
  useApiLeaseCardStorageMutation,
  type IManagerFormValue,
  useApiLeaseCardStorage,
  diffManagerForm,
  useManagerFormValue,
  mergeForm,
} from '@manager'
import { cloneFnJSON } from '@vueuse/core'
import { useQueryClient } from '@tanstack/vue-query'
import { useForm } from 'vee-validate'

export const ManagerFormSubmit = Symbol('ManagerFormSubmit')

export const useManagerForm = () => {
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  const fieldGroupId = getCurrentFieldGroupId()
  const newRelic = useNewRelic()

  const { formValue } = useManagerFormValue()
  const { values: veeValidateForm, handleSubmit } = useForm()

  const { data: originalData } = useApiLeaseCardStorage({
    divisionId,
    leaseId,
    fieldGroupId,
  })

  // Set the form value to the query cache on initialize
  // This way all the values evaluated on initialization will be saved for comparison
  const queryClient = useQueryClient()
  onMounted(async () => {
    await nextTick()
    const _formValue = cloneFnJSON(formValue.value ?? {})
    queryClient.setQueryData(
      [
        'companies',
        divisionId,
        'leases',
        leaseId,
        'storage',
        'node',
        fieldGroupId,
      ],
      _formValue,
    )
  })

  const { mutateAsync: saveFieldStorageList } = useApiLeaseCardStorageMutation(
    divisionId,
    leaseId,
    fieldGroupId,
  )

  const submitHandler = useSubmitHandler<IManagerFormValue>(
    (data) => saveFieldStorageList(data),
    {
      loadingMessage: 'Saving lease...',
      successMessage: 'Lease saved',
      errorMessage: 'Failed to save lease',
      beforeSubmit({ data }) {
        const _originalData = cloneFnJSON(originalData.value ?? {})
        const _formValue = cloneFnJSON(formValue.value ?? {})
        if (
          Object.keys(diffManagerForm(_originalData, _formValue)).length === 0
        ) {
          return false
        }
      },
      onSuccess: () => {
        // The response from `originalData` will be fresh because we invalidated
        // the lease card storage in the mutation `onSuccess` hook
        const response = originalData.value
        if (response) {
          mergeForm({
            target: formValue.value,
            source: cloneFnJSON(response),
          })
        }
      },
    },
  )

  const submit = handleSubmit(() => submitHandler(formValue.value))
  provide(ManagerFormSubmit, submit)

  useConfirmLeave(
    computed(() => {
      const _originalData = cloneFnJSON(originalData.value ?? {})
      const _formValue = cloneFnJSON(formValue.value ?? {})
      const diff = diffManagerForm(_originalData, _formValue)
      const hasDiff = Object.keys(diff).length > 0
      // TODO: Remove log when confirm leave is working for all cases
      if (hasDiff) {
        newRelic.log('[LM]: Confirm leave without saving', {
          customAttributes: {
            original: _originalData,
            modified: _formValue,
            diff,
          },
        })
      }
      return hasDiff
    }),
  )

  return { formValue, veeValidateForm, onSubmit: submit }
}
