import type { UseConfirmOptions } from '@shared/composables/useConfirm'
import type { NavigationGuard } from 'vue-router'
import type { MaybeRefOrGetter } from '@vueuse/shared'

export type UseConfirmLeaveOptions = Omit<
  UseConfirmOptions,
  'bypass' | 'confirm' | 'close' | 'loading' | 'confirmInputText'
>

export function useConfirmLeave(
  isDirty: MaybeRefOrGetter<boolean>,
  options?: UseConfirmLeaveOptions,
) {
  const {
    title = 'Leave without saving?',
    body = 'The current data has not been saved yet, when exiting it will be lost.',
    submitButton,
    cancelButton,
    confirmClass,
    cancelClass,
    onClosed,
    showCloseButton,
  } = options || {}
  const confirm = useConfirm()

  const confirmLeave = () => {
    return new Promise((resolve) => {
      confirm.require({
        title,
        body,
        submitButton,
        cancelButton,
        confirm: () => resolve(true),
        close: () => resolve(false),
        onClosed,
        confirmClass,
        cancelClass,
        showCloseButton,
      })
    })
  }

  tryOnMounted(() => {
    useEventListener(window, 'beforeunload', async (e) => {
      if (toValue(isDirty)) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    })
  })

  const onBeforeRouteLeaveCallback = useThrottleFn<NavigationGuard>(
    async (to, from, next) => {
      // If the form is dirty and the user did not confirm leave,
      // prevent losing unsaved changes by canceling navigation
      if (
        from.path !== to.path &&
        toValue(isDirty) &&
        !(await confirmLeave())
      ) {
        next(false)
      } else {
        // Navigate to next view
        next()
      }
    },
    100,
  )

  onBeforeRouteUpdate(onBeforeRouteLeaveCallback)
  onBeforeRouteLeave(onBeforeRouteLeaveCallback)
}
